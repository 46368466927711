/* eslint-disable no-undef */

import React from "react";
import { connect } from "react-redux";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap
} from "react-google-maps";
import { cloneDeep, uniq, difference as arrayDifference } from "lodash";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import { detailedDiff } from 'deep-object-diff'

import { MAP } from 'react-google-maps/lib/constants';

import MapControl from "./MapControl";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Edit from "@material-ui/icons/Edit";
import Button from "components/CustomButtons/Button.jsx";
import ColorSelector from "components/ColorSelector.jsx";

import { templateActions } from "actions";
import { templateService } from "services";
import { userActions } from "../../actions";


const GOOGLE_MAPS_API_KEY = 'AIzaSyDna_3SM8ZN6Bh7NQcRbUvU_EeJJ3XnMG0';

const DEFAULT_LOCATION = { lat: 40.00483255, lng: -83.02291305 };
const DEFAULT_ZOOM = 17

const OUTLINE_COLOR_DEF = {
  strokeColor: '#000000',
  strokeOpacity: 0,
  strokeWeight: 2,
  fillColor: '#000000',
  fillOpacity: 0.2
}

const SELECTED_OUTLINE_COLOR_DEF = {
  strokeColor: '#7f0000',
  strokeOpacity: 1,
  strokeWeight: 1,
  fillColor: '#b71c1c',
  fillOpacity: 1
}

const SELECTED_OUTLINE_DETAIL_COLOR_DEF = {
  strokeColor: '#7f0000',
  strokeOpacity: 1,
  strokeWeight: 1,
  fillColor: '#000000',
  fillOpacity: 0.2
}

const BAYGROUP_COLOR_DEF = {
  strokeColor: '#7f0000',
  strokeOpacity: 1,
  strokeWeight: 1,
  fillColor: '#000000',
  fillOpacity: 0.2
}

const SELECTED_BAYGROUP_COLOR_DEF = {
  strokeColor: '#7f0000',
  strokeOpacity: 1,
  strokeWeight: 1,
  fillColor: '#b71c1c',
  fillOpacity: 1
}

const BAY_COLOR_DEF = {
  strokeColor: '#7f0000',
  strokeOpacity: 1,
  strokeWeight: 1,
  fillColor: '#000000',
  fillOpacity: 0
}

const SELECTED_BAY_COLOR_DEF = {
  strokeColor: '#7f0000',
  strokeOpacity: 1,
  strokeWeight: 1,
  fillColor: '#b71c1c',
  fillOpacity: 1
}

const MODE_LOTS = 0;
const MODE_BAYGROUPS = 1;
const MODE_BAYS = 2;

const Map = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={props.defaultZoom}
      defaultCenter={props.defaultCenter}
      defaultClickableIcons={false}
      defaultOptions={{
        zoomControl: true,
        minZoom: 15,
        maxZoom: 20,
        streetViewControl: false
      }}
      ref={props.onMapMounted}
      onBoundsChanged={props.onBoundsChanged}
    >
      {props.children}
    </GoogleMap>
  ))
);

class FullScreenMap extends React.Component {

  constructor(props) {
    const { match } = props;
    super(props);

    let editTemplateId = match.params.id;

    this.state = {
      map: null,
      outlinesDisplayed: {},
      bayGroupsDisplayed: {},
      pamsDisplayed: {},
      markers: {},
      selectedZones: {},
      signConfig: {},
      selectedConfig: null,
      zoomLevel: null,
      editTemplateId,
      initialValues: null,
      isFullScreen: false,
      selectorMode: MODE_LOTS,
      lotBayGroupsRetrieved: [],
      lotAreaReferenceIdsRetrieved: [],
      currentLotSelected: null,
      levels: {},
      listeners: {
        outlines: {},
        bayGroups: {},
        bays: {}
      }

    }

    this.handleMapMounted = this.handleMapMounted.bind(this);
    this.handleBoundsChanged = this.handleBoundsChanged.bind(this);
    this.retrieveBayGroupsForLot = this.retrieveBayGroupsForLot.bind(this);
    this.switchSignSelect = this.switchSignSelect.bind(this);
    this.selectOutlines = this.selectOutlines.bind(this);
    this.selectBayGroups = this.selectBayGroups.bind(this);
    this.selectBays = this.selectBays.bind(this);
    this.toggleLevel = this.toggleLevel.bind(this);
    this.cancel = this.cancel.bind(this);
    this.save = this.save.bind(this);
   }
   
  static getDerivedStateFromProps(nextProps, prevState) {
    const { dispatch, outlines, templates, match, loadingOutlines, loading, bayGroups, jurisdictionDefaults, checkingJurisdictionDefaults} = nextProps;
    const { initialValues, holdOnOutlines, holdOnTemplates, lotBayGroupsRetrieved, selectedConfig } = prevState;

    //console.log("getDerivedStateFromProps");

    let jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'));

    let editTemplateId = match.params.id;
    let startingLoadingOutlines = false;
    let startingLoading = false;    

    if ((!loadingOutlines) && (!holdOnOutlines) && (!outlines[jurisdiction.id])) {
      dispatch(templateActions.getOutlines(jurisdiction.id))
      startingLoadingOutlines = true;
    }

    if ((!loading) && (!holdOnTemplates) && (Object.keys(templates).length === 0)) {
      dispatch(templateActions.getTemplates(jurisdiction.id))
      startingLoading = true;
    }

    if ((!checkingJurisdictionDefaults) && (Object.keys(jurisdictionDefaults).length === 0)) {
      dispatch(userActions.checkJurisdictionDefaults(jurisdiction.id));
    }

    let newLotBayGroupsRetrieved = uniq([...Object.keys(bayGroups), ...lotBayGroupsRetrieved]);

    if ((!initialValues) && (templates)) {
      let template = templates[editTemplateId];
      if (!template) {
        return {};
      }

      let newSelectedConfig = selectedConfig;
      if ((Object.keys(template.signConfig).length > 0) && (selectedConfig == null)) {
        newSelectedConfig = Object.keys(template.signConfig)[0];
      }

      let newState = {
        editTemplateId, 
        initialValues: cloneDeep(template.affected),
        selectedZones: {...cloneDeep(template.affected), lotOutlines: [], lotOutlinesForSelectedBayGroups: {}, bayGroupsForSelectedBays: {}, bayGroupsForBays: {} },
        signConfig: template.signConfig,
        selectedConfig: newSelectedConfig,
        jurisdiction,
        lotBayGroupsRetrieved: newLotBayGroupsRetrieved,
        holdOnOutlines: holdOnOutlines || false,
        holdOnTemplates: holdOnTemplates || false
      };

      if (startingLoadingOutlines) newState['holdOnOutlines'] = true;
      if (startingLoading) newState['holdOnTemplates'] = true;

      return newState;
    }
    
    return {};
  }

  shouldComponentUpdate(nextProps, nextState) {
    //console.log("shouldComponentUpdate()", nextProps, nextState)

    const renderStates = [
      'selectedConfig',
      'selectedZones',
      'initialValues',
      'lotAreaReferenceIdsRetrieved',
      'lotBayGroupsRetrieved',
      'selectorMode',
      'map',
      'currentLevel',
      'currentLotSelected'
    ]

    const renderProps = [
      'bayGroups',
      'jurisdictionDefault',
      'outlines',
      'saving'
    ]

    // to reduce comparison time, don't compare zones and paths as that could be really lengthy
    let currentState = {}
    let newState = {}
    
    if (renderStates.length) {
        for (let i = 0; i < renderStates.length; i++) {
        currentState[renderStates[i]] = this.state[renderStates[i]]
        newState[renderStates[i]] = nextState[renderStates[i]]
      }
    } else {
      currentState = {...this.state};
      newState = {...nextState};
    }

    let needsRenderUpdate = false
    const propsDiff = detailedDiff(this.props, nextProps)
    const stateDiff = detailedDiff(currentState, newState)

    for (let i = 0; i < renderProps.length; i++) {
      if ((propsDiff.added[renderProps[i]] !== undefined) ||
          (propsDiff.updated[renderProps[i]] !== undefined) ||
          (propsDiff.deleted[renderProps[i]] !== undefined)) {
        needsRenderUpdate = true
        break
      }
    }

    if (!needsRenderUpdate) {
      for (let i = 0; i < renderStates.length; i++) {
        if ((stateDiff.added[renderStates[i]] !== undefined) ||
            (stateDiff.updated[renderStates[i]] !== undefined) ||
            (stateDiff.deleted[renderStates[i]] !== undefined)) {
          needsRenderUpdate = true
          break
        }
      }
    }

    // if ((Object.keys(propsDiff.added).length !== 0) || 
    //      (Object.keys(propsDiff.deleted).length !== 0) ||
    //      (Object.keys(propsDiff.updated).length !== 0)) {
    //     console.log("props have changed ", propsDiff)
    // }
    
    // if ((Object.keys(stateDiff.added).length !== 0) || 
    //      (Object.keys(stateDiff.deleted).length !== 0) ||
    //      (Object.keys(stateDiff.updated).length !== 0)) {
    //   console.log("state have changed ", stateDiff)
    // }

    return needsRenderUpdate
  }


  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      selectedZones,
      bayGroupsDisplayed,
      pamsDisplayed,
      lotAreaReferenceIdsRetrieved
    } = prevState;

    if (Object.keys(selectedZones).length === 0) return;
    let bayGroupsToRetrieve = [];

    //console.log("componentDidUpdate()");
    for (let i = 0; i < selectedZones.bayGroups.length; i++) {
      let bayGroupAreaReferenceId = selectedZones.bayGroups[i];
      if ((!bayGroupsDisplayed[bayGroupAreaReferenceId]) && (lotAreaReferenceIdsRetrieved.filter(e => e = bayGroupAreaReferenceId).length === 0)) {
        bayGroupsToRetrieve.push(bayGroupAreaReferenceId);
      }
    }

    let pamReferenceIdsToRetrieve = [];
    for (let i = 0; i < selectedZones.pamReferenceIds.length; i++) {
      let pamReferenceId = selectedZones.pamReferenceIds[i];
      if ((!pamsDisplayed[pamReferenceId]) && (lotAreaReferenceIdsRetrieved.filter(e => e = pamReferenceId).length === 0)) {
        pamReferenceIdsToRetrieve.push(pamReferenceId);
      }
    }

    if ((bayGroupsToRetrieve.length > 0) || (pamReferenceIdsToRetrieve.length > 0)) {
      this.retrieveLotAreaReferencesFor(bayGroupsToRetrieve, pamReferenceIdsToRetrieve);
    }

  }

  switchSignSelect(configId) {
    const { selectedConfig } = this.state;

    if (configId !== selectedConfig) {
      this.setState({
        selectedConfig: configId
      });
    }
  }

  handleMapMounted(ref) {
    let infoWindow = new google.maps.InfoWindow();

    this.setState({ map: ref, infoWindow });

    function handleFullScreenChange() {
      this.setState({
        isFullScreen: document.fullScreenElement || document.mozFullScreen || document.webkitIsFullScreen || document.msIsFullScreen || false
      });
    }
    // eslint-disable-next-line
    handleFullScreenChange = handleFullScreenChange.bind(this);

    window.document.addEventListener('webkitfullscreenchange', handleFullScreenChange, false);
    window.document.addEventListener('mozfullscreenchange', handleFullScreenChange, false);
    window.document.addEventListener('fullscreenchange', handleFullScreenChange, false);
    window.document.addEventListener('MSFullscreenChange', handleFullScreenChange, false);
  }

  handleBoundsChanged() {
    const { map, jurisdiction } = this.state

    if (map) {
      const center = map.getCenter()
      // if (!center) {
      //   let defaultLocation = jurisdictionDefaults['DEFAULT_LOCATION'] || DEFAULT_LOCATION
      //   map.setCenter({ lat: defaultLocation.latitude})
      // }

      const newZoomLevel = map.getZoom()

      localStorage.setItem(
        `${jurisdiction.id}_center`,
        center.lat() + ',' + center.lng() + ',' + newZoomLevel
      )

      this.setState({ zoomLevel: newZoomLevel });

    }
  }

  updateOutlines(outlines) {
    const {
      outlinesDisplayed,
      selectedZones,
      signConfig,
      map,
      infoWindow,
      listeners,
      levels,
      currentLotSelected
    } = this.state;

    if ((!map) || (!outlines)) return;

    //console.log("updateOutlines()");
    let selectedOutlines = selectedZones.lots || [];

    for (let i = 0; i < outlines.length; i++) {
      let outline = outlines[i];

      if (!levels[outline.areaReferenceId]) levels[outline.areaReferenceId] = {};
      if ((outline.additionalTypeDetail) && (outline.additionalTypeDetail.defaultLevel) && (!levels[outline.areaReferenceId].currentLevel)) {
        levels[outline.areaReferenceId].levels = outline.additionalTypeDetail.levels;
        levels[outline.areaReferenceId].currentLevel = outline.additionalTypeDetail.defaultLevel;
      }

      let outlineColorDef = OUTLINE_COLOR_DEF
      const selectedOutlineIndex = selectedOutlines.findIndex(selected => selected.areaId === outline.areaReferenceId);
      if (selectedOutlineIndex !== -1) {
        outlineColorDef = SELECTED_OUTLINE_COLOR_DEF;
        outlineColorDef.fillColor = signConfig[selectedOutlines[selectedOutlineIndex].configId].color;
      }
      outlineColorDef = (selectedZones.lotOutlines.findIndex(selected => selected === outline.areaReferenceId) !== -1 ? SELECTED_OUTLINE_DETAIL_COLOR_DEF : outlineColorDef);


      if (!outlinesDisplayed[outline.areaReferenceId]) {
        if ((outline.paths) && (outline.paths.length > 0)) {
          outlinesDisplayed[outline.areaReferenceId] = [this.createPolygon(outline, outlineColorDef)];
        } else {
          outlinesDisplayed[outline.areaReferenceId] = this.createComplexObjects(outline, outlineColorDef);
        }

        listeners.outlines[outline.areaReferenceId] = [];
        // eslint-disable-next-line
        outlinesDisplayed[outline.areaReferenceId].forEach(path => {
            listeners.outlines[outline.areaReferenceId].push(path.addListener('mouseover', (e) => {
              const { selectedZones, signConfig } = this.state;

              let outlineIndexInLots = selectedZones.lots.findIndex(selected => selected.areaId === outline.areaReferenceId);
              if (outlineIndexInLots >= 0) {
                let description = signConfig[selectedZones.lots[outlineIndexInLots].configId].description;
                infoWindow.setContent(description);
                infoWindow.open(map.context[MAP]);
                infoWindow.setPosition(e.latLng);    
              } else {
                infoWindow.close();
              }        
            }));
            listeners.outlines[outline.areaReferenceId].push(path.addListener('mouseout', () => {
              infoWindow.close();
            }));
            listeners.outlines[outline.areaReferenceId].push(path.addListener('click', e => {
         
            const {
              selectedZones, selectorMode, lotBayGroupsRetrieved, bayGroupsDisplayed, signConfig, selectedConfig, infoWindow
            } = this.state;
            const { bayGroups, readOnlyMode } = this.props;

            if (readOnlyMode) {
              
              if (currentLotSelected !== outline.areaReferenceId) {
                this.setState({ currentLotSelected: outline.areaReferenceId });
              }
              return;
            }

            let newSelectedZones = cloneDeep(selectedZones);
            let newOutlineColor = OUTLINE_COLOR_DEF;

            let outlineIndexInLots = selectedZones.lots.findIndex(selected => selected.areaId === outline.areaReferenceId);
            let outlineIndexInLotOutlines = selectedZones.lotOutlines.findIndex(selected => selected === outline.areaReferenceId);
            let outlineBayGroupsRetrieved = lotBayGroupsRetrieved.findIndex(lot => lot === outline.areaReferenceId);

            switch (selectorMode) {
              case MODE_LOTS:
                if (currentLotSelected !== outline.areaReferenceId) {
                  this.setState({ currentLotSelected: outline.areaReferenceId });
                }

                // currently selected in lots
                if (outlineIndexInLots !== -1) {

                  // if current signconfig is different to whats here, then replace it
                  if (newSelectedZones.lots[outlineIndexInLots].configId !== selectedConfig) {
                    newSelectedZones.lots[outlineIndexInLots].configId = selectedConfig;
                    newOutlineColor = SELECTED_OUTLINE_COLOR_DEF;
                    newOutlineColor.fillColor = signConfig[selectedConfig].color;
                    infoWindow.setContent(signConfig[selectedConfig].description);
                  } else { // others switch selection off
                    newSelectedZones.lots.splice(outlineIndexInLots, 1);  
                  }
                  break;
                }

                // currently selected in lot outlines
                if (outlineIndexInLotOutlines !== -1) {
                  let lotBayGroups = bayGroups[outline.areaReferenceId];

                  // if there are any selected bayGroups within the outline then ignore request
                  if ((selectedZones.lotOutlinesForSelectedBayGroups[outline.areaReferenceId]) && (selectedZones.lotOutlinesForSelectedBayGroups[outline.areaReferenceId].length > 0)) {
                    this.setState({ currentLotSelected: outline.areaReferenceId });
                    return;
                  }

                  // check for bays selected too
                  for (let i = 0; i < lotBayGroups.bayGroups.length; i++) {
                    if (selectedZones.bayGroupsForSelectedBays[lotBayGroups.bayGroups[i].areaReferenceId]) return;
                  }

                  lotBayGroups.bayGroups.forEach(bayGroup => {
                    if (bayGroupsDisplayed[bayGroup.areaReferenceId]) {
                      bayGroupsDisplayed[bayGroup.areaReferenceId].forEach(path => { 
                        path.setMap(null)
                        google.maps.event.clearInstanceListeners(path);
                      });
                      delete listeners.bayGroups[bayGroup.areaReferenceId];
                      delete bayGroupsDisplayed[bayGroup.areaReferenceId];
                    }
                  });

                  // remove outline
                  newSelectedZones.lotOutlines.splice(outlineIndexInLotOutlines, 1);
                  break;
                }

                // in neither
                newSelectedZones.lots.push({ areaId: outline.areaReferenceId, configId: selectedConfig });
                newOutlineColor = SELECTED_OUTLINE_COLOR_DEF;
                newOutlineColor.fillColor = signConfig[selectedConfig].color;
                break;

              case MODE_BAYGROUPS:
              case MODE_BAYS:
                if (currentLotSelected !== outline.areaReferenceId) {
                  this.setState({ currentLotSelected: outline.areaReferenceId });
                }

                // currently selected in lots
                if (outlineIndexInLots !== -1) {
                  newSelectedZones.lots.splice(outlineIndexInLots, 1);  
                  break;
                }

                // currently selected in lot outlines then ignore click
                if (outlineIndexInLotOutlines !== -1) return;
                
                // in neither
                newSelectedZones.lotOutlines.push(outline.areaReferenceId);
                newOutlineColor = SELECTED_OUTLINE_DETAIL_COLOR_DEF;
                if (outlineBayGroupsRetrieved === -1) {
                  this.retrieveBayGroupsForLot(outline.areaReferenceId);
                }
                break;

              default:
                break;
            }

            // change color of outline
            path.setOptions({
              strokeColor: newOutlineColor.strokeColor,
              strokeOpacity: newOutlineColor.strokeOpacity,
              strokeWeight: newOutlineColor.strokeWeight,
              fillColor: newOutlineColor.fillColor,
              fillOpacity: newOutlineColor.fillOpacity
            });

            let newState = {
              selectedZones: newSelectedZones,
              currentLotSelected: outline.areaReferenceId,
            }

            this.setState(newState);
          }));
        })
      }
    }
  }

  updateBayGroups(bayGroups, outlines) {
    const {
      bayGroupsDisplayed,
      selectedZones,
      signConfig,
      map,
      listeners,
      levels
    } = this.state;

    if ((!map) || (!outlines)) return;

    //console.log("updateBayGroups", bayGroups, selectedZones);

    let selectedBayGroups = selectedZones.bayGroups || [];
    let lotOutlines = selectedZones.lotOutlines || [];

    Object.keys(bayGroups).forEach(lotAreaReferenceId => {
      let currentLevel = null;
      if ((levels[lotAreaReferenceId]) && (levels[lotAreaReferenceId].currentLevel)) currentLevel = levels[lotAreaReferenceId].currentLevel;

      if (lotOutlines.findIndex(outlineReferenceId => outlineReferenceId === lotAreaReferenceId) !== -1) {
        let lotBayGroups = bayGroups[lotAreaReferenceId];
        // might not be loaded yet so check and return if not
        if (!lotBayGroups) return;

        for (let i = 0; i < lotBayGroups.bayGroups.length; i++) {
          let bayGroup = lotBayGroups.bayGroups[i];

          let bayGroupColorDef = BAYGROUP_COLOR_DEF;
          const selectedBayGroupIndex = selectedBayGroups.findIndex(selected => selected.areaId === bayGroup.areaReferenceId);
          if (selectedBayGroupIndex !== -1) {
            bayGroupColorDef = SELECTED_BAYGROUP_COLOR_DEF;
            bayGroupColorDef.fillColor = signConfig[selectedBayGroups[selectedBayGroupIndex].configId].color;
          }

          // create polygon if doesn't exist
          if ((!bayGroupsDisplayed[bayGroup.areaReferenceId]) && ((currentLevel === null) || (currentLevel === bayGroup.additionalTypeDetail.level))) {
            let bayGroupOutline = {
              paths: bayGroup.outline
            };
            bayGroupsDisplayed[bayGroup.areaReferenceId] = [this.createPolygon(bayGroupOutline, bayGroupColorDef, 1)];
          } else {
            if ((bayGroupsDisplayed[bayGroup.areaReferenceId]) && ((currentLevel) && (currentLevel !== (bayGroup.additionalTypeDetail.level || null)))) {
              bayGroupsDisplayed[bayGroup.areaReferenceId].forEach(path => {
                google.maps.event.clearInstanceListeners(path);
                path.setMap(null);
              });
              delete listeners.bayGroups[bayGroup.areaReferenceId];
              delete bayGroupsDisplayed[bayGroup.areaReferenceId];
            }
          }

          if ((bayGroupsDisplayed[bayGroup.areaReferenceId]) && (!listeners.bayGroups[bayGroup.areaReferenceId])) {
            // eslint-disable-next-line
            listeners.bayGroups[bayGroup.areaReferenceId] = bayGroupsDisplayed[bayGroup.areaReferenceId].map(path => {
              return path.addListener('click', e => {
                const {
                  outlinesDisplayed,
                  selectedZones,
                  selectorMode,
                  signConfig,
                  selectedConfig
                } = this.state;

                const { readOnlyMode } = this.props;

                const lot = outlines.find(outline => outline.areaReferenceId === lotAreaReferenceId);

                if (readOnlyMode) return;

                // if outlines not loaded then ignore request
                if (Object.keys(outlinesDisplayed).length === 0) return;

                let newSelectedZones = cloneDeep(selectedZones);
                let newBayGroupColor = BAYGROUP_COLOR_DEF;

                let bayGroupIndexInBayGroups = selectedZones.bayGroups.findIndex(selected => selected.areaId === bayGroup.areaReferenceId);

                switch (selectorMode) {
                  case MODE_LOTS:
                    google.maps.event.trigger(outlinesDisplayed[lotAreaReferenceId][0], 'click', { sourceBayGroup: bayGroup.areaReferenceId });
                    return;

                  case MODE_BAYGROUPS:
                    // baygroup is currently selected
                    if ((selectedZones.bayGroupsForSelectedBays[bayGroup.areaReferenceId]) && (selectedZones.bayGroupsForSelectedBays[bayGroup.areaReferenceId].length > 0)) {
                      return;
                    }

                    if (bayGroupIndexInBayGroups !== -1) {
                      if (newSelectedZones.bayGroups[bayGroupIndexInBayGroups].configId !== selectedConfig) {
                        newSelectedZones.bayGroups[bayGroupIndexInBayGroups].configId = selectedConfig;
                        newBayGroupColor = SELECTED_BAYGROUP_COLOR_DEF;
                        newBayGroupColor.fillColor = signConfig[selectedConfig].color;
                      } else {
                        newSelectedZones.bayGroups.splice(bayGroupIndexInBayGroups, 1);
                        newSelectedZones.lotOutlinesForSelectedBayGroups[lotAreaReferenceId] = newSelectedZones.lotOutlinesForSelectedBayGroups[lotAreaReferenceId].filter(e => e !== bayGroup.areaReferenceId);
                        if (newSelectedZones.lotOutlinesForSelectedBayGroups[lotAreaReferenceId].length === 0) delete newSelectedZones.lotOutlinesForSelectedBayGroups[lotAreaReferenceId];  
                      }
                      break;
                    }

                    // baygroup is not currently selected
                    newSelectedZones.bayGroups.push({ areaId: bayGroup.areaReferenceId, configId: selectedConfig });

                    if (!newSelectedZones.lotOutlinesForSelectedBayGroups[lotAreaReferenceId]) newSelectedZones.lotOutlinesForSelectedBayGroups[lotAreaReferenceId] = [];
                    newSelectedZones.lotOutlinesForSelectedBayGroups[lotAreaReferenceId].push(bayGroup.areaReferenceId);
                    newBayGroupColor = SELECTED_BAYGROUP_COLOR_DEF;
                    newBayGroupColor.fillColor = signConfig[selectedConfig].color;
                    break;

                  case MODE_BAYS:
                    return;
                  
                  default:
                    break;
                }

                path.setOptions({
                  strokeColor: newBayGroupColor.strokeColor,
                  strokeOpacity: newBayGroupColor.strokeOpacity,
                  strokeWeight: newBayGroupColor.strokeWeight,
                  fillColor: newBayGroupColor.fillColor,
                  fillOpacity: newBayGroupColor.fillOpacity
                });

                let newState = {
                  selectedZones: newSelectedZones,
                  currentLotSelected: lot.areaReferenceId
                }

                this.setState(newState);
              }); // end listener
            }) // end map
          } // end if
        } // end for
      } // end if
    }); // end Object.keys forEach
  }

  updateBays(bayGroups, outlines) {
    const {
      pamsDisplayed,
      selectedZones,
      signConfig,
      map,
      listeners,
      selectorMode,
      levels
    } = this.state;

    if ((!map) || (!outlines)) return;

    //console.log("updateBays()");
    let selectedBays = selectedZones.pamReferenceIds || [];
    let lotOutlines = selectedZones.lotOutlines || [];

    Object.keys(bayGroups).forEach(lotAreaReferenceId => {

      let currentLevel = null;
      if ((levels[lotAreaReferenceId]) && (levels[lotAreaReferenceId].currentLevel)) currentLevel = levels[lotAreaReferenceId].currentLevel;

      if (lotOutlines.findIndex(outlineReferenceId => outlineReferenceId === lotAreaReferenceId) !== -1) {

        let lotBayGroups = bayGroups[lotAreaReferenceId];

        if (selectedZones.lots.findIndex(selected => selected.areaId === lotAreaReferenceId) !== -1) {
          return;
        }

        // might not be loaded yet so check and return if not
        if (!lotBayGroups) return;

        for (let i = 0; i < lotBayGroups.bayGroups.length; i++) {
          let bayGroup = lotBayGroups.bayGroups[i];

          // if bayGroup is selected as a whole, then don't render individual
          if (selectedZones.bayGroups.findIndex(selection => selection.areaId === bayGroup.areaReferenceId) !== -1) {
            continue;
          }
          
          for (let j = 0; j < bayGroup.pamReferenceIds.length; j++) {
            let reference = bayGroup.pamReferenceIds[j];
            
            let bayColorDef = BAY_COLOR_DEF;
            const selectedBayIndex = selectedBays.findIndex(selected => selected.referenceId === reference.pamReferenceId);
            if (selectedBayIndex !== -1) {
              bayColorDef = SELECTED_BAY_COLOR_DEF;
              bayColorDef.fillColor = signConfig[selectedBays[selectedBayIndex].configId].color;
            }
  
            // if mode is not in bay selection mode then on to the next one
            if ((selectorMode !== MODE_BAYS) && (selectedBayIndex === -1)) continue;

            // create polygon if doesn't exist
            if ((!pamsDisplayed[reference.pamReferenceId]) && ((currentLevel === null) || (currentLevel === bayGroup.additionalTypeDetail.level))) {
              if ((reference.path) && (reference.path.length > 0)) {
                pamsDisplayed[reference.pamReferenceId] = [this.createPolygon({ paths: reference.path}, bayColorDef, 2)];
              } 
              // add it to the list of selected bays in the baygroup
              if (selectedBayIndex !== -1) {
                if (!selectedZones.bayGroupsForSelectedBays[bayGroup.areaReferenceId]) selectedZones.bayGroupsForSelectedBays[bayGroup.areaReferenceId] = [];
                selectedZones.bayGroupsForSelectedBays[bayGroup.areaReferenceId].push(reference.pamReferenceId);
              }
            } else {
              if ((pamsDisplayed[reference.pamReferenceId]) && ((currentLevel) && (currentLevel !== (bayGroup.additionalTypeDetail.level || null)))) {
                pamsDisplayed[reference.pamReferenceId].forEach(path => {
                  google.maps.event.clearInstanceListeners(path);
                  path.setMap(null);
                });
                delete listeners.bays[reference.pamReferenceId];
                delete pamsDisplayed[reference.pamReferenceId];
              }
            }

            if ((pamsDisplayed[reference.pamReferenceId]) && (!listeners.bays[reference.pamReferenceId])) {
              // eslint-disable-next-line
              listeners.bays[reference.pamReferenceId] = pamsDisplayed[reference.pamReferenceId].map(path => {
                return path.addListener('click', e => {
                  const {
                    outlinesDisplayed,
                    bayGroupsDisplayed,
                    selectedZones,
                    signConfig,
                    selectedConfig,
                    selectorMode
                  } = this.state;

                  const { readOnlyMode } = this.props;

                  const lot = outlines.find(outline => outline.areaReferenceId === lotAreaReferenceId);

                  if (readOnlyMode) return;

                  // if outlines not loaded then ignore request
                  if (Object.keys(outlinesDisplayed).length === 0) return;

                  let newSelectedZones = cloneDeep(selectedZones);
                  let newBayColor = BAY_COLOR_DEF;

                  let bayIndexInBays = selectedZones.pamReferenceIds.findIndex(selected => selected.referenceId === reference.pamReferenceId);

                  switch (selectorMode) {
                    case MODE_LOTS:
                    case MODE_BAYGROUPS:
                      google.maps.event.trigger(bayGroupsDisplayed[bayGroup.areaReferenceId][0], 'click', { sourcePamReferenceId: reference.pamReferenceId });
                      break;
                    
                    case MODE_BAYS:
                    // bay is currently selected
                      if (bayIndexInBays !== -1) {
                        if (newSelectedZones.pamReferenceIds[bayIndexInBays].configId !== selectedConfig) {
                          newSelectedZones.pamReferenceIds[bayIndexInBays].configId = selectedConfig;
                          newBayColor = SELECTED_BAY_COLOR_DEF;
                          newBayColor.fillColor = signConfig[selectedConfig].color;
                        } else {
                          newSelectedZones.pamReferenceIds.splice(bayIndexInBays, 1);
                          newSelectedZones.bayGroupsForSelectedBays[bayGroup.areaReferenceId] = newSelectedZones.bayGroupsForSelectedBays[bayGroup.areaReferenceId].filter(e => e !== reference.pamReferenceId);
                          if (newSelectedZones.bayGroupsForSelectedBays[bayGroup.areaReferenceId].length === 0) delete newSelectedZones.bayGroupsForSelectedBays[bayGroup.areaReferenceId];  
                        }
                        break;  
                      }

                      newSelectedZones.pamReferenceIds.push({ referenceId: reference.pamReferenceId, configId: selectedConfig });
                      if (!newSelectedZones.bayGroupsForSelectedBays[bayGroup.areaReferenceId]) newSelectedZones.bayGroupsForSelectedBays[bayGroup.areaReferenceId] = [];
                      newSelectedZones.bayGroupsForSelectedBays[bayGroup.areaReferenceId].push(reference.pamReferenceId);
                      newBayColor = SELECTED_BAY_COLOR_DEF;
                      newBayColor.fillColor = signConfig[selectedConfig].color;
                      break;
                    
                    default:
                      break;
                  }

                  path.setOptions({
                    strokeColor: newBayColor.strokeColor,
                    strokeOpacity: newBayColor.strokeOpacity,
                    strokeWeight: newBayColor.strokeWeight,
                    fillColor: newBayColor.fillColor,
                    fillOpacity: newBayColor.fillOpacity
                  });

                  let newState = {
                    selectedZones: newSelectedZones,
                    currentLotSelected: lot.areaReferenceId
                  }

                  this.setState(newState);
                });
              });
            }
          }
        }
      } // end if
    }); // end Object.keys forEach
  }

  createPolygon(zone, colorDef, zIndex = 0) {
    const { map } = this.state
    
    let polygonPaths = zone.paths.map(path => {
      return { lat: path.latitude, lng: path.longitude }
    })

    const path = new google.maps.Polygon({
      paths: polygonPaths,
      strokeColor: colorDef.strokeColor,
      strokeOpacity: colorDef.strokeOpacity,
      strokeWeight: colorDef.strokeWeight,
      fillColor: colorDef.fillColor,
      fillOpacity: colorDef.fillOpacity,
      zIndex
    })

    path.setMap(map.context[MAP])

    return path
  }

  createComplexObjects(zone, colorDef, zIndex = 0) {

    const { map } = this.state

    let pathCollections = zone.complexPaths.map(collection => {
      let objectPaths = collection.map(object => {
        return object.map(path => {
          return { lat: path.latitude, lng: path.longitude }
        })
      })

      const polygon = new google.maps.Polygon({
        paths: objectPaths,
        strokeColor: colorDef.strokeColor,
        strokeOpacity: colorDef.strokeOpacity,
        strokeWeight: colorDef.strokeWeight,
        fillColor: colorDef.fillColor,
        fillOpacity: colorDef.fillOpacity,
        zIndex
      })
  
      polygon.setMap(map.context[MAP])
      return polygon
    })

    return pathCollections;
  }

  mapControls() {
    const { isFullScreen, selectorMode, signConfig, selectedConfig, currentLotSelected, levels } = this.state;
    const { readOnlyMode } = this.props;

    //console.log("mapControls()");
    let buttons = [
      { task: "selectOutlines", color: "primary", icon: Edit, title: "Lots", displayIfReadOnlyMode: false, showInFullScreen: true, disabled: selectorMode===MODE_LOTS },
      { task: "selectBayGroups", color: "primary", icon: Edit, title: "BayGroups",  displayIfReadOnlyMode: false, showInFullScreen: true, disabled: selectorMode===MODE_BAYGROUPS },
      { task: "selectBays", color: "primary", icon: Edit, title: "Bays",  displayIfReadOnlyMode: false, showInFullScreen: true, disabled: selectorMode===MODE_BAYS },
      { task: "switchSignSelect", signConfigColor: true, icon: Edit, title: "SignConfig", displayAsDropDown: true,  displayIfReadOnlyMode: true, showInFullScreen: true },      
      { task: "save", color: "success", icon: Edit, title: "Save",  displayIfReadOnlyMode: false, showInFullScreen: false },
      { task: "cancel", color: "danger", icon: Edit, title: "Cancel",  displayIfReadOnlyMode: false, showInFullScreen: false },
      { task: "cancel", color: "success", icon: Edit, title: "Ok", displayIfReadOnlyMode: true, displayIfNotReadOnlyMode: false, showInFullScreen: false },
    ];

    if ((currentLotSelected) && (levels[currentLotSelected]) && (levels[currentLotSelected].levels) && (levels[currentLotSelected].levels.length > 1)) {
     buttons.splice(3, 0, { task: "toggleLevel", color: "success", icon: Edit, title: `Level: ${levels[currentLotSelected].currentLevel}`, displayIfReadOnlyMode: true, showInFullScreen: true });
    }

    if (isFullScreen) {
      buttons = buttons.filter(o => o.showInFullScreen);
    }

    if (readOnlyMode) {
      buttons = buttons.filter(o => o.displayIfReadOnlyMode);
    } else {
      buttons = buttons.filter(o => ((o.displayIfNotReadOnlyMode === undefined) || (o.displayIfNotReadOnlyMode !== undefined && o.displayIfNotReadOnlyMode !== false)));
    }

    let configIds = Object.keys(signConfig);
    let configDescriptions = configIds.map(configId => <div><ColorSelector color={signConfig[configId].color} displayOnly={true}/>{signConfig[configId].description}</div>);

    return (
      <div style={{textAlign: 'center'}}>
        <GridContainer>
          {buttons.map((button, key) => {
            
            if (button.signConfigColor) {
              if (!button.displayAsDropDown) {
                return (<GridItem key={key}><Button style={{ background: signConfig[selectedConfig].color}} disabled={button.disabled || false} onClick={(e) => this[button.task](e)}>
                {button.title}
              </Button></GridItem>);
              } else {
                return (<GridItem key={key}><CustomDropdown 
                  buttonText="SIGNCONFIG"
                  buttonProps={{
                    round: false,
                    fullWidth: true,
                    style: { background: signConfig[selectedConfig].color, marginBottom: "0" },
                  }}
                  dropdownHeader="Configurations"
                  dropdownList={configDescriptions}
                  onItemClick={(e) => this[button.task](configIds[e])}
                  /></GridItem>);
              }
            }

            return (<GridItem key={key}><Button color={button.color} disabled={button.disabled || false} onClick={(e) => this[button.task](e)}>
              {button.title}
            </Button></GridItem>);
          })}
        </GridContainer>
      </div>
    )
  }

  retrieveLotAreaReferencesFor(bayGroups, pamReferenceIds) {
    const { jurisdiction, lotAreaReferenceIdsRetrieved, lotBayGroupsRetrieved, selectedZones } = this.state;

    let retrievableBayGroups = arrayDifference(bayGroups, lotAreaReferenceIdsRetrieved);
    let retrievablePamReferenceIds = arrayDifference(pamReferenceIds, lotAreaReferenceIdsRetrieved);
    
    if ((retrievableBayGroups.length > 0) || (retrievablePamReferenceIds.length > 0)) {
      templateService.lotAreaReferenceIdsFor(jurisdiction.id, retrievableBayGroups.map(e => e.areaId), retrievablePamReferenceIds.map(e => e.referenceId))
      .then(references => {
        let newSelectedZones = cloneDeep(selectedZones);
        if (!newSelectedZones.lotOutlines) newSelectedZones.lotOutlines = [];
        if (!newSelectedZones.lotOutlinesForSelectedBayGroups) newSelectedZones.lotOutlinesForSelectedBayGroups = {};

        newSelectedZones.lotOutlines = uniq([...newSelectedZones.lotOutlines, ...references.map(e => e.lotAreaReferenceId)]);

        references.forEach(reference => {
          if (lotBayGroupsRetrieved.filter(e => e === reference.lotAreaReferenceId).length === 0) {
            this.retrieveBayGroupsForLot(reference.lotAreaReferenceId);
          }

          let bayGroupReferences = references.filter(e => e.bayGroupAreaReferenceId);
          bayGroupReferences.forEach(bayGroupReference => {
            if (!newSelectedZones.lotOutlinesForSelectedBayGroups[bayGroupReference.lotAreaReferenceId]) newSelectedZones.lotOutlinesForSelectedBayGroups[bayGroupReference.lotAreaReferenceId] = [];
            newSelectedZones.lotOutlinesForSelectedBayGroups[bayGroupReference.lotAreaReferenceId].push(bayGroupReference.bayGroupAreaReferenceId);
          })
          
          this.setState({
            selectedZones: newSelectedZones
          });
        });
      });
      
      // set the state so this doesn't repeat
      this.setState({
        lotAreaReferenceIdsRetrieved: [...lotAreaReferenceIdsRetrieved, ...retrievableBayGroups, ...retrievablePamReferenceIds]
      });
    
    }
  }

  retrieveBayGroupsForLot(lotAreaReferenceId) {
    const { jurisdiction, lotBayGroupsRetrieved } = this.state;
    const { dispatch } = this.props;

    if (!lotBayGroupsRetrieved.includes(lotAreaReferenceId)) {
      let newLotBayGroupRetrieved = cloneDeep(lotBayGroupsRetrieved);
      dispatch(templateActions.getBayGroupsForLot(jurisdiction.id, lotAreaReferenceId));
      
      newLotBayGroupRetrieved.push(lotAreaReferenceId);
  
      this.setState({
        lotBayGroupsRetrieved: newLotBayGroupRetrieved
      })  
    }
  }

  removeDisplayedFromMap() {
    const { outlinesDisplayed, bayGroupsDisplayed, pamsDisplayed, listeners } = this.state;

    Object.keys(outlinesDisplayed).forEach(outlineId => {
      outlinesDisplayed[outlineId].forEach(path => {
        path.setMap(null);
        google.maps.event.clearInstanceListeners(path);
      })
      delete outlinesDisplayed[outlineId];
      delete listeners.outlines[outlineId];
    });

    Object.keys(bayGroupsDisplayed).forEach(bayGroupId => {
      bayGroupsDisplayed[bayGroupId].forEach(path => {
        path.setMap(null);
        google.maps.event.clearInstanceListeners(path);
      })
      delete bayGroupsDisplayed[bayGroupId];
      delete listeners.bayGroups[bayGroupId];
    });

    Object.keys(pamsDisplayed).forEach(pamReferenceId => {
      pamsDisplayed[pamReferenceId].forEach(path => {
        path.setMap(null);
        google.maps.event.clearInstanceListeners(path);
      })
      delete pamsDisplayed[pamReferenceId];
      delete listeners.bays[pamReferenceId];
    });
  }

  removeNonSelectedBaysFromMap() {
    const { pamsDisplayed, listeners, selectedZones } = this.state;

    Object.keys(pamsDisplayed).forEach(pamReferenceId => {
      if (selectedZones.pamReferenceIds.findIndex(selected => selected === pamReferenceId) === -1) {
        pamsDisplayed[pamReferenceId].forEach(path => {
          path.setMap(null);
          google.maps.event.clearInstanceListeners(path);
          delete listeners.bays[pamReferenceId];
          delete pamsDisplayed[pamReferenceId];
        })
      }
    });

  }

  selectOutlines(event) {
    event.stopPropagation();

    //console.log("selectOutlines()");
    this.removeNonSelectedBaysFromMap();
    this.setState({
      selectorMode: MODE_LOTS
    });
  }

  selectBayGroups(event) {
    event.stopPropagation();

    //console.log("selectBayGroups()");
    this.removeNonSelectedBaysFromMap();
    this.setState({
      selectorMode: MODE_BAYGROUPS
    });
  }

  selectBays(event) {
    event.stopPropagation();

    //console.log("selectBayGroups()");
    this.setState({
      selectorMode: MODE_BAYS
    });
  }

  toggleLevel(event) {
    const { currentLotSelected, levels } = this.state;
    event.stopPropagation();

    if ((currentLotSelected) && (levels[currentLotSelected])) {
      let currentLevel = levels[currentLotSelected].currentLevel;
      let lotLevels = levels[currentLotSelected].levels;

      let index = lotLevels.findIndex(e => e === currentLevel);

      if (index !== -1) {
        if (index === lotLevels.length - 1) {
          levels[currentLotSelected].currentLevel = lotLevels[0];
        } else {
          levels[currentLotSelected].currentLevel = lotLevels[index + 1];
        }

        this.setState({ levels, currentLevel: `${currentLotSelected}_${levels[currentLotSelected].currentLevel}` });

      }
    }
  }

  cancel(event) {
    const { history } = this.props;

    event.stopPropagation();
    this.removeDisplayedFromMap();
    history.goBack();

    this.setState({
      outlinesDisplayed: {},
      bayGroupsDisplayed: {},
      pamsDisplayed: {},
      selectedZones: {},
      initialValues: {},
      stopRendering: true
    });
  }

  save(event) {
    const { dispatch, history, templates } = this.props;
    const { selectedZones, editTemplateId } = this.state;

    let user = JSON.parse(localStorage.getItem('user'));

    event.stopPropagation();
    this.removeDisplayedFromMap();

    const previousTemplate = templates[editTemplateId];
    let updatedTemplate = {
      id: previousTemplate.id,
      description: previousTemplate.description,
      affected: {
        lots: selectedZones.lots || [],
        bayGroups: selectedZones.bayGroups || [],
        pamReferenceIds: selectedZones.pamReferenceIds || []
      },
      signConfig: previousTemplate.signConfig,
      attributes: previousTemplate.attributes,
      version: previousTemplate.version,
      published: false,
      templatecontainer: previousTemplate.templatecontainer.id,
      modifiedBy: user.id,
    }

    dispatch(templateActions.updateTemplate(updatedTemplate));
    history.goBack();

    this.setState({
      outlinesDisplayed: {},
      bayGroupsDisplayed: {},
      pamsDisplayed: {},
      selectedZones: {},
      initialValues: {},
      stopRendering: true
    });
  }

  render() {

    const { outlines, bayGroups, jurisdictionDefaults } = this.props;
    const { stopRendering, jurisdiction } = this.state;

    //console.log("render()")

    if (!jurisdiction) return null;
    if (Object.keys(jurisdictionDefaults).length === 0) return null;
    
    if (!stopRendering) {
      if (Object.keys(outlines).length > 0) this.updateOutlines(outlines[jurisdiction.id]);
      if (Object.keys(bayGroups).length > 0) {
        this.updateBayGroups(bayGroups, outlines[jurisdiction.id]);
        this.updateBays(bayGroups, outlines[jurisdiction.id]);
      } 
    }

    let defaultLocation = jurisdictionDefaults['DEFAULT_LOCATION'] ? { lat: jurisdictionDefaults['DEFAULT_LOCATION'].latitude, lng: jurisdictionDefaults['DEFAULT_LOCATION'].longitude } : DEFAULT_LOCATION;
    let defaultZoom = jurisdictionDefaults['DEFAULT_ZOOM'] || DEFAULT_ZOOM;

    let storedLocationParameters = localStorage.getItem(`${jurisdiction.id}_center`);

    if (storedLocationParameters !== null) {
      let parameters = storedLocationParameters.split(',')
      defaultLocation = {
        lat: Number(parameters[0]),
        lng: Number(parameters[1])
      }
      defaultZoom = Number(parameters[2])
    }

    return (
      <Map
        defaultZoom={defaultZoom}
        defaultCenter={defaultLocation}
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100vh` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        onMapMounted={this.handleMapMounted}
        onBoundsChanged={this.handleBoundsChanged}
      >
      <MapControl controlPosition={2}>
        {this.mapControls()}
      </MapControl>
      </Map>
    )
  }

}


function mapStateToProps(state) {
  const { templates, users, alert } = state;

  if (!templates) {
    return {};
  }

  return {
    outlines: templates.outlines || {},
    bayGroups: templates.lotBayGroups || {},
    templates: templates.templates || {},
    templateGroups: templates.templateGroups || {},
    templateContainers: templates.templateContainers || {},
    templateInstances: templates.templateInstances || {},
    lotAreaReferences: templates.lotAreaReferences || [],
    loadingOutlines: templates.loadingOutlines || false,
    bayGroupLotsRequested: templates.bayGroupLotsRequested || [],
    loading: templates.loading || false,
    saving: templates.saving || false,
    deleting: templates.deleting || false,
    alert: alert || null,
    jurisdictionDefaults: users.jurisdictionDefaults || {},
    checkingJurisdictionDefaults: users.checkingJurisdictionDefaults || false
  };
}

export default connect(mapStateToProps)(FullScreenMap);



